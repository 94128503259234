<template>
    <div>
      <el-card class="box-card">
        <div class="table_top fl_wrap">
          <template v-if=(dataPickerView)>
            <el-select v-model="sTxType"
                        class="fl_right pl10 pb05"
                        placeholder="TxType"
                        @change="selTxTypeChange">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-date-picker
                  class="fl_right pr05 pb05"
                  v-model="sDateValue"
                  type="daterange"
                  range-separator="~"
                  placeholder="Pick a date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  start-placeholder="Start Date"
                  end-placeholder="End Date"
                  @change ="pickerChange"
                  >
            </el-date-picker>
          </template>
        </div>

        <!-- S: pc list//-->
        <div class="el-table el-table--fit el-table--striped
                    el-table--enable-row-hover
                    el-table--enable-row-transition"
              style="width: 100%; margin-bottom: 15px;">
            <div class="el-table__header-wrapper ">
              <table cellspacing="0" cellpadding="0" border="0"
                      class="el-table__header">
                <thead class="has-gutter">
                  <tr class="">
                    <th  v-for="(col, index) in columnes"
                          :key="index"
                          :width="col.width"
                          class="el-table__cell">
                      <div class="cell" >{{$t(col.label)}}</div></th>
                  </tr></thead>
              </table>
            </div>
            <div class="el-table__body-wrapper is-scrolling-none">
              <table cellspacing="0" cellpadding="0" border="0"
                     class="el-table__body">
                <tbody is="transition-group" name="list">
                <tr v-for="(data, idx) in tableData"
                    v-bind:key="data[columnes[1].prop]"
                    :class="(idx%2 ==0)?'el-table__row' :'el-table__row el-table__row--striped'" >
                  <td v-for="(col, index) in columnes"
                      :key="index"
                      :width="col.width"
                      :class="(col.router !='')?'el-table__cell tdlink':'el-table__cell '+col.class"
                      @click="routerGo(col.router, data[col.key] )">
                    <div class="cell" v-if="col.prop == 'tx_status'">
                      <el-result icon="success"  v-if="data[col.prop]==='success'"></el-result>
                      <el-result icon="error"  v-else-if="data[col.prop]==='fail'"></el-result>
                    </div>
                    <div class="cell" v-else-if="col.prop == 'tx_id'">
                        {{data[col.prop] | readMore}}
                    </div>
                    <div class="cell  fl_right mr20" v-else-if="col.prop == 'amount'">
                        <span v-if="data['tx_type'] ==='withdraw_delegator_reward'
                                    && data[col.prop] ===0 ">more</span>
                        <span v-else v-html="$options.filters.currency(data[col.prop])"></span>
                        {{data['symbol']}}
                    </div>
                    <div class="cell  fl_right mr20" v-else-if="col.prop == 'gas_fee'">
                        <span v-html="$options.filters.currency(data[col.prop])"></span>
                        {{$t(data['fee_symbol'])}}
                    </div>
                    <div class="cell" v-else-if="col.prop == 'tx_type'">
                      {{$t(data[col.prop])}}
                    </div>
                    <div class="cell  fl_right mr05" v-else-if="col.prop == 'time_ago'">
                      {{ data['tx_dt']}} UTC
                      ({{data[col.prop]}})</div>
                    <div class="cell" v-else>{{data[col.prop]}}</div>
                  </td>
                  </tr>
                  </tbody>
                </table>
                <span v-if="tableData.length == 0" class="no_data">{{$t('txNoData')}}</span>
            </div>
        </div>
        <!-- E: pc list//-->
        <!-- S: mobile list//-->
        <div class="sub_table el-table-m">
          <transition-group >
            <dl class="display_grid" :key="'dl'+idx"
                 v-for="(data, idx) in tableData">
            <template  v-for="(col, index) in columnes">
              <dt :key="index" >{{$t(col.label)}}</dt>
              <dd :key="col.prop" :class="(col.router !='')? 'tdlink': '' +col.class"
                  @click="routerGo(col.router, data[col.key] )"
                  v-if="col.prop == 'tx_id'">
                   {{data[col.prop] | readMore}}</dd>
              <dd :key="col.prop" :class="col.class" v-else-if="col.prop == 'tx_type'">
                  {{$t(data[col.prop])}}</dd>
              <dd :key="'dd'+index" :class="col.class" v-else-if="col.prop == 'tx_status'">
                  <el-result icon="success"  v-if="data[col.prop]==='success'"></el-result>
                  <el-result icon="error"  v-else-if="data[col.prop]==='fail'"></el-result>
              </dd>
              <dd :key="'time'+index"  :class="col.class" v-else-if="col.prop == 'time_ago'">
                   {{data['tx_dt']}} UTC ({{data[col.prop]}})</dd>
              <dd :key="col.prop" :class="col.class"
                              v-else-if="col.prop == 'amount'">
                   <span v-if="data['tx_type'] ==='withdraw_delegator_reward'
                                    && data[col.prop] ===0 ">more</span>
                   <span v-else v-html="$options.filters.currency(data[col.prop])"></span>
                   {{data['symbol']}}</dd>
              <dd :key="col.prop" :class="col.class"
                              v-else-if="col.prop == 'gas_fee'">
                   <span v-html="$options.filters.currency(data[col.prop])"></span>
                   {{$t(data['fee_symbol'])}}</dd>
              <dd :key="col.label" v-else
                  :class="(col.router !='')? 'tdlink': '' +col.class"
                  @click="routerGo(col.router, data[col.key] )">{{data[col.prop]}}</dd>
            </template>
            </dl>
          </transition-group >
            <span v-if="tableData.length == 0" class="no_data">{{$t('txNoData')}}</span>
        </div>
        <!-- E: mobile list//-->

        <!-- S: page//-->
        <el-pagination
          style="float:center"
          background
          layout="prev, pager, next"
          :page-size="10"
          :total="total_data"
          :pager-count="5"
          @current-change="handleCurrentChange"
           v-if="total_data > 0" >
        </el-pagination>
        <!-- E: page//-->
        </el-card>
    </div>
</template>

<script>
export default {
  name: 'Transactiontable',
  props: {
    columnes: Array,
    tableData: Array,
    loading: Boolean,
    total_data: Number,
    dataPickerView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: true,
      options: [{
        value: '',
        label: this.$t('txTypeOptAll'),
      }, {
        value: 'begin_redelegate',
        label: this.$t('begin_redelegate'),
      }, {
        value: 'begin_unbonding',
        label: this.$t('begin_unbonding'),
      }, {
        value: 'delegate',
        label: this.$t('delegate'),
      }, {
        value: 'send',
        label: this.$t('send'),
      }, {
        value: 'submit_proposal ',
        label: this.$t('submit_proposal'),
      }, {
        value: 'vote',
        label: this.$t('vote'),
      }, {
        value: 'withdraw_delegator_reward',
        label: this.$t('withdraw_delegator_reward'),
      }],
      sTxType: '',
      sDateValue: '',
    };
  },
  methods: {
    selTxTypeChange(val) {
      this.$emit('selTxTypeChange', val);
    },
    pickerChange(val) {
      this.$emit('pickerChange', val);
    },
    handleCurrentChange(val) {
      this.$emit('handleCurrentChange', val);
    },
    routerGo(routerName, id) {
      if (routerName === '') return;
      this.$router.push({
        name: routerName, params: { me: id },
      });
    },
  },
};
</script>
<style>
.list-enter-active {
  transition: all 2s;
}
.list-leave-active{
transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
 }
.list-enter, .list-leave-to{
  opacity: 0;
  transform: translateX(30px);
}
.list-move {
  transition: transform 2s;
}
</style>
