<template>
    <div class="sub_container">
      <div class="sub_wrapper">
        <h2 class="sub-title1">{{ $t('menu3-list') }}</h2>
        <STtable :columnes ="columnes_data"
                 :tableData ="tableData_data"
                 :total_data = "total_data"
                 :loading="loading"
                 :dataPickerView = dataPickerView
                 @handleCurrentChange="handleCurrentChange"
                 @pickerChange="dateSearch"
                 @selTxTypeChange="txTypeSearch" >
        </STtable>
      </div>
    </div>
</template>
<script>
import STtable from '@/components/STtable.vue';

export default {
  name: 'Transactions',
  components: {
    STtable,
  },
  data() {
    return {
      loading: true,
      columnes_data: [{
        label: 'columnResult', prop: 'tx_status', width: '10%', class: '', router: '', key: '',
      },
      {
        label: 'columnHeight', prop: 'block_nm', width: '12%', class: '', router: 'blocksDetail', key: 'block_nm',
      },
      {
        label: 'columnTxHash', prop: 'tx_id', width: '18%', class: '', router: 'transactionDetail', key: 'tx_id',
      },
      {
        label: 'columnTxType', prop: 'tx_type', width: '10%', class: '', router: '', key: '',
      },
      {
        label: 'columnAmount', prop: 'amount', width: '17%', class: '', router: '', key: '',
      },
      {
        label: 'columnTime', prop: 'time_ago', width: '*', class: '', router: '', key: '',
      },
      ],
      tableData_data: [],
      total_data: 1,
      dataPickerView: true,
      searchDate: [],
      searchTxType: '',
      nIntervId: null,
    };
  },
  methods: {
    txTypeSearch(val) {
      this.searchTxType = val;
      this.loading = true;
      this.getData(1);
    },
    dateSearch(val) {
      if (val === null) {
        this.searchDate = [];
      } else {
        this.searchDate = val;
      }
      this.loading = true;
      this.getData(1);
    },
    handleCurrentChange(val) {
      this.loading = true;
      this.getData(val);
    },
    getData(page) {
      let pathUrl = `/txs?page_no=${page}&list_size=10`;
      if (this.searchDate.length > 0) pathUrl = `${pathUrl}&s_dt=${this.searchDate[0]}&e_dt=${this.searchDate[1]}`;
      if (this.searchTxType !== '') pathUrl = `${pathUrl}&tx_type=${this.searchTxType}`;
      this.loading = true;
      this.$axios.get(pathUrl)
        .then((res) => {
          if (res.data.ret_code === 200) {
            this.total_data = res.data.data.tx_tot;
            this.tableData_data = res.data.data.txs;
          }
        })
        .catch((error) => { this.$message.error(error); })
        .finally(() => {
          this.loading = false;
        });

      if (page === 1 && this.nIntervId === null) {
        this.nIntervId = setInterval(this.getData, 10000, 1);
      } else if (page > 1 && this.nIntervId != null) {
        clearInterval(this.nIntervId);
        this.nIntervId = null;
      }
    },
  },
  created() {
    this.getData(1);
  },
  beforeDestroy() {
    clearInterval(this.nIntervId);
  },
};
</script>
